import React, { Component } from "react"
import { Link } from "gatsby"

import Layout from "../../components/layout"
import SEO from "../../components/seo"

import imgProfileChrisBell from "../../images/chris-bell-profile-square.jpg"
import imgProfileEmmaBell from "../../images/gfx/emma-bell-shuttleid.jpg"
import "../index.css";
import "../case-study.css";

import Nav from "../../components/nav"
import AOS from 'aos';
import 'aos/dist/aos.css';
import Contact from "../../components/contact"
import Hero from "../../components/hero-standard"
import Footer from "../../components/footer"

class OurStoryPage extends Component {

  componentDidMount(){
    AOS.init({
      duration : 2000
    });
  }

  componentDidUpdate() {
    AOS.refresh();
  }

  render() {
    return (
      <Layout>
        <SEO title="Our story" />
        <Nav activeTab="about" />
        <div>
            <div>

              <Hero h1="Our story" h2="The journey of our family business" />

              <div className="about-page">

                <div className="article">
                  <div className='article-intro article-inner about-main'>

                    <h3>A gap in the market</h3>
                    <img className="about-profile" src={imgProfileChrisBell} alt="Chris Bell" />
                    <p>ShuttleID founder, Chris Bell, has worked alongside transport operators over the past 15 years, developing systems including websites, booking engines, ticket e-commerce systems, and service alerting systems. Chris has also lead technology teams for commercial products used by millions internationally.</p>
                    <p>On his journey, Chris discovered transport operators were in real need of a cost-effective solution to help them manage home-to-school transport.</p>
                    <p>Ticketing and bus pass systems were too costly to justify running only twice per day, 190 days per year. They were also complex for drivers to understand. The systems available on the market were poor value and they didn't listen to the needs of operators.</p>
                    <h3>Humble beginnings</h3>
                    <p>In 2019, <a href="https://www.route-one.net/news/e_ticketing_platform_shuttleid_launches_with_first_customer/">Chris launched ShuttleID</a>, a solution tailored around the needs of its first customer. With portable hardware costing a fraction of the price usually charged by ticket machine companies, in the first week of operation <Link to='/case-study/'>ShuttleID solved a £10,000 ticket fraud problem</Link>.</p>
                    <p>During 2020, the global pandemic posed challenges within the bus and coach industry, however <Link to="/case-study-cambridge-buses-help-local-authority-save-time-money/">ShuttleID helped operators</Link> manage their school services safely and efficiently; <Link to="/case-study-applegates-guarantee-income-safety-during-covid-by-going-cashless/">going cashless, contact tracing, service alerts and more</Link>. ShuttleID expanded and onboarded customers across England, Wales and Northern Ireland.</p>
                    <h3>Growth continues</h3>
                    <img className="about-profile" src={imgProfileEmmaBell} alt="Emma Bell" />
                    <p>Just days into 2021, ShuttleID launched its innovative <Link to='/bus-open-data-vehicle-location-package/'>Bus Open Data package</Link>, helping operators save huge expense otherwise required for compliance with vehicle location data.</p>

                    <p>With growth showing no signs of slowing, Chris was joined by wife Emma, who left behind a successful law career to join ShuttleID, helping put processes in place and heading up sales. The number of operators ShuttleID work has since trebled.</p>

                    <h3>The next chapter</h3>
                    <p>ShuttleID continues to evolve and, in early 2022, ShuttleID launched the <Link className="link" to="/school-bus-tracking-system-app/">LIVE add-on</Link>, enabling live vehicle tracking for passengers and live boarding data for operations in the back office.</p>
                    <p>This game-changing addition means transport providers can now offer the best technology to their customers at an affordable price, whilst still retaining full control over the commercial relationship with their customers.</p>
                    <p>Chris and Emma would love to hear about your story and how they can help. You can speak with the team on <a href="mailto:info@shuttleid.uk">info@shuttleid.uk</a> or call 0333 344 9868.</p>

                  </div>

                </div>
              </div>
              <Contact />
            </div>
        </div>
        <Footer />
      </Layout>
    );
  }
};

export default OurStoryPage
